import Store from '@/store'
import moment from 'moment'
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFontsBn from 'pdfmake/build/vfs_pdf_kal_fonts_bn'

const returnPdfDetails = async (base64Logo, vm, rider, totalValue, hubName) => {
  try {
    Store.commit('mutateCommonProperties', {
      loading: true
    }) 

    pdfMake.vfs = pdfFontsBn.pdfMake.vfs  
    pdfMake.fonts = {
      Kalpurush: {
        normal: 'Kalpurush.ttf',
        bold: 'Kalpurush.ttf',
        italics: 'Kalpurush.ttf',
        bolditalics: 'Kalpurush.ttf'
      }
    }

    const pdfContent = [
      { image: 'data:image/png;base64,' + base64Logo, width: 70, style: 'logo'  },
      { text: 'Hub : ' + hubName +', Rider : ' + rider + ', Total Parcel : ' + vm.length +',  Total Value : ' + totalValue + ' Tk' + ', Date '+ moment().format('YYYY-MM-DD'), alignment: 'left' }
    ]

    pdfContent.push({ text: '', style: 'space' })

    const allRows = [
      [
        { text: 'SL', style: 'th', alignment: 'center', bold: true },
        { text: 'Order Id', style: 'th', alignment: 'center', bold: true },
        { text: 'Merchant', style: 'th', alignment: 'center', bold: true },
        { text: 'Customer', style: 'th', alignment: 'center', bold: true },
        { text: 'Address', style: 'th', alignment: 'center', bold: true },
        { text: 'Ex.', style: 'th', alignment: 'center', bold: true },
        { text: 'Price', style: 'th', alignment: 'center', bold: true },
        { text: 'Instruction/Reason', style: 'th', alignment: 'center', bold: true }
      ]
    ]
    
    vm.forEach((item, index) => {
        const orderInfo = 'ID:'+ item.id + ',\nDate:' + item.date + (item.ref_id ? ',Ref:'+ item.ref_id : '')
        const merchant = item.merchant.business + ', ' + item.merchant.mobile
        const customer = item.name + ', ' + item.mobile
        const instructionReason = (item.instruction ? item.instruction : '') + (item.reason ? '/'+item.reason : '')

        allRows.push([
            { text: index + 1, alignment: 'left', style: 'td' },
            { text: orderInfo, alignment: 'left', style: 'td' },
            { text: merchant, alignment: 'left', style: 'td' },
            { text: customer, alignment: 'left', style: 'td' },
            { text: item.address, alignment: 'left', style: 'td' },
            { text: item.exchange == 1 ? 'Yes' : '-', alignment: 'center', style: 'td' },
            { text: item.price, alignment: 'center', style: 'td' },
            { text: instructionReason, alignment: 'left', style: 'td' }
        ]) 
    })

    pdfContent.push({
        table: {
            headerRows: 1,
            widths: ['3%', '18%', '15%', '15%', '25%', '5%', '4%', '15%'],
            body: allRows
        }
    })

    var docDefinition = {
      content: pdfContent,
      pageSize: 'A4',
      pageOrientation: 'landscape',
      footer: function (currentPage, pageCount) {
        return {
          table: {
            body: [
                [
                  { text: "Page " + currentPage.toString() + ' of ' + pageCount, alignment: 'right', style: 'normalText', /*margin: [0, 0, 0, 0]*/ }
                ]
            ]
          }
        };
      },
      defaultStyle: {
        font: 'Kalpurush'
      },
      styles: {
        th: {
          fontSize: 8,
          margin: [3, 3, 3, 3]
        },
        td: {
          fontSize: 10,
          margin: [3, 3, 3, 3]
        },
        org: {
          fontSize: 12,
          margin: [3, 3, 3, 3]
        },
        space: {
          margin: [5, 0, 0, 5]
        },
        header: {
          fontSize: 17,
          bold: true,
          margin: [0, 5, 0, 0]
        },
        logo: {
          margin: [0, 0, 0, 0]
        },
        normalText: {
          border: 0
        },
        hh: {
          fontSize: 13,
          bold: true,
          margin: [10, 10, 25, 20]
        }
      }
    }
    pdfMake.createPdf(docDefinition, null, null, null).print()
  } catch (error) {
    if (error) {
      console.log('err => ', error)
    }
  }
  Store.commit('mutateCommonProperties', {
    loading: false
  })
}

export default {
  returnPdfDetails
}
