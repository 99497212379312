<template>
    <div class="content-wrapper">
        <!-- Content Header (Page header) -->
        <div class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-6">
                        <h1 class="m-0 text-dark">Rider Assign</h1>
                    </div><!-- /.col -->
                    <div class="col-sm-6">
                        <img id="logoId" src="../../../public/images/logo.png" style="width:40px; height:40px; display:none;">
                    </div><!-- /.col -->
                </div><!-- /.row -->
            </div><!-- /.container-fluid -->
        </div>
        <!-- /.content-header -->
        <!-- Main content -->
        <section class="content">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12">
                        <div class="card">
                            <div class="card-header">
                                <div class="row">
                                    <div class="col-xl-2 col-lg-2 col-md-2 col-sm-6 col-xs-12">
                                        <div class="form-group">
                                            <label for="title">Rider <span class="text-danger" title="Required">*</span></label>
                                            <v-select name="hub_id"
                                                v-model="form.rider_id"
                                                label="text"
                                                :reduce="item => item.id"
                                                :options= riderList
                                                :placeholder="$t('globalTrans.select')"
                                            />
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-xs-12">
                                            <div class="form-group">
                                            <label for="date">Barcode Type or Scan</label>
                                            <input type="text" class="form-control" v-model="itemId" @keyup.enter="keepOrder" placeholder="Scan barcode or type order id and press enter"> 
                                        </div>
                                    </div>
                                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-xs-12">
                                        <div class="form-group" style="margin-top:26px">
                                            <button class="btn btn-info" type="submit" @click.prevent="submitData">Rider Assign</button>
                                            <b style="margin-left:10px; font-size:18px">Total:{{ totalParcel }}</b>
                                            <b style="margin-left:10px; font-size:18px">Scaned:{{ form.order_ids.length }}</b>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card-body">
                                <span v-for="(orderId, index) in form.order_ids" :key="index" class="btn btn-sm btn-warning mb-1 mr-1">
                                    {{ orderId }} <i class="ml-1 fas fa-times" @click="removeRow(orderId)"></i>
                                </span>
                            </div>
                            <div class="card-body">
                                <b-overlay :show="loader">
                                    <div class="table-responsive">
                                        <table class="table table-bordered table-striped table-sm">
                                            <thead>
                                                <tr>
                                                    <th>SL</th>
                                                    <th>Order ID</th>
                                                    <th>Merchant</th>
                                                    <th>Customer</th>
                                                    <th>Address</th>
                                                    <th>Status</th>
                                                    <th>Price</th>
                                                    <th>Destination Hub</th>
                                                    <th>Order Date</th>
                                                    <th>Instruction</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(item, index) in listData" :key="index">
                                                    <td>{{ index + 1 }}</td>
                                                    <td :class="item.urgent == 1 ? 'text-red' : 'text-black'" :title="item.urgent == 1 ? 'Urgent' : ''">{{ item.id }}</td>
                                                    <td>{{ item.merchant.business }}</td>
                                                    <td>{{ item.name }}, {{ item.mobile }}</td>
                                                    <td>{{ item.address }}</td>
                                                    <td><span :class="'status-'+item.status">{{ getStatus(item.status) }}</span></td>
                                                    <td>{{ item.price }}</td>
                                                    <td>{{ item.destination ? item.destination.name : '' }}</td>
                                                    <td>{{ item.date }}</td>
                                                    <td>{{ item.instruction }}</td>
                                                </tr>
                                            </tbody>
                                        </table>           
                                    </div>    
                                </b-overlay>
                            </div>
                        </div>
                    </div>
                </div>
            </div><!-- /.container-fluid -->
        </section>
    </div>
</template>
<script>
import config from '@/config'
import ExportPdf from './AssignedOrder'

export default {
    name:'RiderAssign',
    data(){
        return {
            loader: false,
            listData: [],
            itemId: '',
            totalParcel: 0,
            riderDetails: '',
            form: {
                rider_id: '',
                order_ids: []
            }
        }
    },
    created() {
        this.loadData()
    },
    watch: {
        'form.rider_id': function (newVal, oldVal) {
            if (newVal != oldVal && this.form.from_status == 7) {
                this.loadData()
            }
            this.getRiderDetails(newVal)
        }
    },
    computed : {
        authUser () {
            return this.$store.state.authUser
        },
        customState () {
            return this.$store.state
        },
        riderList () {
            if (this.authUser.role_id == 7) {
                return this.$store.state.commonObj.riderList.filter(el => el.hub_id === this.authUser.hub_id)
            }
            
            return this.$store.state.commonObj.riderList
        }
    },
    methods:{
        getRiderDetails (riderId) {
            const rider = this.$store.state.commonObj.riderList.find(el => el.id == riderId)
            this.riderDetails = rider ? (rider.text + ', '+ rider.mobile) : ''
        },
        async loadData() {     
            this.totalParcel = 0
            this.form.order_ids = []
            this.loader = true 
            const params = Object.assign(this.form, { hub_id: this.authUser.hub_id })
            const response = await config.getData('/hub/rider-assign/list', params)
            this.loader = false
            if (response.success){
                this.totalParcel = response.data.length
                this.listData = response.data
            } else {
                this.listData = []
            } 
        },
        getStatus (status) {
            const tmpStatus = this.customState.statusList.find(tmp => tmp.id == status)
            return typeof tmpStatus != 'undefined' ? tmpStatus.text : status
        },
        keepOrder() {
            const tmpItem = this.listData.find(el => el.id === parseInt(this.itemId))
            if (typeof tmpItem !== 'undefined') {
                this.form.order_ids.push(this.itemId)
                this.listData = this.listData.filter(el => el.id !== parseInt(this.itemId))
                this.totalParcel -= 1
            } else {
                this.$toast.error({
                    title: 'Error',
                    message: 'Sorry, parcel not found',
                    color: '#dc3545'
                })
            }  
            this.itemId = ''
        },
        removeRow(itemId){
            console.log('item id = ', itemId)
            this.form.order_ids = this.form.order_ids.filter(el => parseInt(el) !== parseInt(itemId))
        },
        async submitData(){     
            if (this.form.rider_id) {
                this.loader = true 
                const response = await config.postData('/hub/rider-assign/scan-submit', this.form)
                this.loader = false
                if (response.success){    
                    this.form.order_ids=[]  
                    if (response.total > 0) {
                        this.pdfDownload(response.data, response.total)
                    }
                    this.form.rider_id = ''
                    this.loadData()     
                    this.$toast.success({
                        title: 'Success',
                        message: response.message,
                        color: '#218838'
                    })
                } else {
                    this.$toast.error({
                        title: 'Error',
                        message: response.message,
                        color: '#dc3545'
                    })                    
                } 
            } else {
                this.$toast.error({
                    title: 'Error',
                    message: 'Please select Rider',
                    color: '#dc3545'
                })
            }
        },
        pdfDownload(datas, totalValue) {
            const hub = this.hubList.find(el => el.id == this.authUser.hub_id)
            const hubName = hub ? hub.text : ''
            const base64Logo = this.getBase64Logo(document.getElementById('logoId'))
            ExportPdf.returnPdfDetails(base64Logo, datas, this.riderDetails, totalValue, hubName)
        },
        getBase64Logo (logo) {
            var canvas = document.createElement('canvas')
            canvas.width = logo.width
            canvas.height = logo.height
            var ctx = canvas.getContext('2d')
            ctx.drawImage(logo, 0, 0)
            var dataURL = canvas.toDataURL('image/png')
            return dataURL.replace(/^data:image\/(png|jpg);base64,/, '')
        },
        removeItem(index){
            this.form.order_ids.splice(index, 1)
        }
    }
}
</script>